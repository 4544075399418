    import React, { useEffect, useState } from "react";
import Banner from "../components/banner/Banner";
import ProjectCard from "../components/homeProjects/ProjectCard";
import TitlePage from "../components/titlePage/TitlePage";
import Button from "../components/button/Button";
import Slider from "../components/slider/Slider";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useOpenStrype, useTopToPage } from "../lib/scrypts";
import { checkEmail } from "../toolkit/slices/confirmEmailSlice";

import { Helmet } from "react-helmet-async";
import {
  setActiveAuthModal,
  setActiveModalName,
} from "../toolkit/slices/authorization/authorizationModalSlice";
import {
  setToken,
  setUID,
} from "../toolkit/slices/authorization/resetPasswordSlice";
import { getUser } from "../toolkit/slices/authorization/getUserSlice";
// import Subscription from '../components/subscription/Subscription';
import SeoBlock from "../components/seoBlock/SeoBlock";
import { setPageType } from "../toolkit/slices/checkTypePageSlice";
import FilterGrid from "../components/filter/FilterGrid";
import {
  getHomePortfolio,
  resetFilterTags,
  setPaginationNumPage,
} from "../toolkit/slices/account/portfolioUserSlice";
import { getFiltersAll } from "../toolkit/slices/getFiltersSlice";
import Pagination from "../components/pagination/Pagination";
import { removeHashtags } from "../toolkit/slices/roomTypeSlice";
import { googleAuth } from "../toolkit/slices/authorization/socialAuthSlice";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import AttemptsOverModal from "../components/modal/AttemptsOverModal";
import { gtmEvent } from "../lib/gtm";

const Home = ({ pageType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageArr = useSelector((state) => state.portfolioUser.data);
  const onOnload = useSelector((state) => state.portfolioUser.onLoadPage);
  const [urlParams] = useSearchParams();
  const code = urlParams.get("code");
  const social = urlParams.get("social");
  const token = urlParams.get("token");
  const user_id = urlParams.get("user_id");
  const uid = urlParams.get("uid");
  const isLogin = urlParams.get("isLogin");
  const [isViewCTA, setIsViewCTA] = useState(false);

  useEffect(() => {
    dispatch(setPageType(pageType));
    if (onOnload) {
      dispatch(getFiltersAll());
      dispatch(resetFilterTags());
      dispatch(setPaginationNumPage(0));
      dispatch(getHomePortfolio());
    }
  }, [dispatch, onOnload, pageType]);

  useEffect(() => {
    if (code != null) {
      if (social === "google") {
        dispatch(googleAuth({ code: encodeURIComponent(code) }));
        // navigate('/');
      }
    }
  }, [code, social, navigate, dispatch]);

  useEffect(() => {
    if (token !== null && user_id !== null) {
      dispatch(checkEmail({ key: token, user_id: user_id }));
    } else if (isLogin === "false") {
      dispatch(setActiveAuthModal(true));
      dispatch(setActiveModalName("Login stripe"));
    }
  }, [token, user_id, isLogin, dispatch]);

  useEffect(() => {
    if (token !== null && uid !== null) {
      dispatch(setActiveAuthModal(true));
      dispatch(setActiveModalName("Change password"));
      dispatch(setUID(uid));
      dispatch(setToken(token));
    }
  }, [token, uid, dispatch]);

  const [isAttempts, setIsAttempts] = useState(false);
  const userInfo = useSelector((state) => state.getUser.userData);
  const dataStorage = JSON.parse(localStorage.getItem("data"));

  const isAuth = localStorage.getItem("key");

  useEffect(() => {
    if (isAuth) {
      dispatch(getUser());
    }
  }, [isAuth, dispatch]);

  const variants = {
    hidden: {
      y: "30px",
      opacity: 0,
    },
    visible: {
      y: "0px",
      opacity: 1,
    },
  };
  let increment = 1;

  const projectsView = imageArr.map((slide, index) => {
    increment++;
    if (increment >= 2) {
      increment = 1 / 10;
    }
    return (
      <motion.div
        key={index}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
      >
        <motion.div
          variants={variants}
          transition={{ type: "Tween", delay: 0.1 * increment, duration: 0.7 }}
        >
          <ProjectCard
            id={slide.id}
            link={slide.image}
            tags={slide.tags}
            imageArr={imageArr}
          />
        </motion.div>
      </motion.div>
    );
  });

  useTopToPage();

  useOpenStrype();

  const clearQuiz = () => {
    dispatch(removeHashtags());
  };

  useEffect(() => {
    if (!sessionStorage.getItem("ctaTime")) {
      sessionStorage.setItem("ctaTime", new Date().getTime());
    }

    if (!sessionStorage.getItem("cta") && sessionStorage.getItem("ctaTime")) {
      console.log(
        +sessionStorage.getItem("ctaTime") + 60000,
        new Date().getTime()
      );
      if (
        parseInt(+sessionStorage.getItem("ctaTime")) + 60000 <
        new Date().getTime()
      ) {
        setIsViewCTA(true);
      }
    }
  }, []);

  return (
    <>
      {isViewCTA && (
        <div
          className="titleSmall"
          onClick={(e) => {
            setIsViewCTA(false);
            sessionStorage.setItem("cta", "true");
          }}
        >
          <AttemptsOverModal
            isButton={true}
            title={
              "Got a minute? Help a budding startup refine our products with your feedback through our survey."
            }
            link={"https://forms.gle/e3kuZCo9LSu1SAuT7"}
            lineBreak={10}
          />
        </div>
      )}

      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://paintit.ai/interior-ai/" />
        <title>
          Explore AI Interior Design Generator for Residential Properties
        </title>
        <meta
          property="og:title"
          content={
            "Explore AI Interior Design Generator for Residential Properties"
          }
        />
        <meta
          name="twitter:title"
          content={
            "Explore AI Interior Design Generator for Residential Properties"
          }
        />
        <meta
          name="description"
          content={
            "Experience the power of Paintit.ai to generate your own unique interior design visualisation of your room in minutes!"
          }
        />
        <meta
          property="og:description"
          content={
            "Experience the power of Paintit.ai to generate your own unique interior design visualisation of your room in minutes!"
          }
        />
        <meta
          name="twitter:description"
          content={
            "Experience the power of Paintit.ai to generate your own unique interior design visualisation of your room in minutes!"
          }
        />
      </Helmet>
      {isAttempts ? (
        <AttemptsOverModal
          title={`Sorry, your attempts are over, you need to ${"Top Up"}`}
          topUp
          isActive={isAttempts}
          setIsActive={setIsAttempts}
          lineBreak={4}
        />
      ) : null}
      {/* <!-- Try featured2.png...featured6.png to find the best style. -->  */}

      <div className="container">
        <TitlePage
          title={
            "Experience the power of AI [break] generate your unique AI interior visualisation [break] in minutes!"
          }
          h1
          lineBreak={4}
          size={"normal"}
          animation={true}
        />
        <div className="row center">
          {"attempts_left" in userInfo &&
          (+userInfo.attempts_left <= 0 || +dataStorage.attempts_left <= 0) ? (
            <Button
              link={"/interior-ai/"}
              color={"green"}
              text={"generate it!"}
              onClick={() => {
                setIsAttempts(true);
                gtmEvent("Create_Now_Resedential", "category");
                clearQuiz();
              }}
            />
          ) : (
            <Button
              link={"/interior-ai/mode-options/"}
              color={"green"}
              text={"generate it!"}
              onClick={() => {
                clearQuiz();
                gtmEvent("Create_Now_Resedential", "category");
              }}
            />
          )}
        </div>
      </div>
      <Slider />
      <div className="projects">
        <div id="projectsGrid" className="container">
          <TitlePage
            h6={"latest renders"}
            title={"Check out the visualisations [break] our users created"}
            lineBreak={3}
            size={"medium"}
            animation={true}
          />

          <FilterGrid pageType={pageType} />
          <div className="projectsGrid">{projectsView}</div>
          {/* <LoadMore text={'Load more...'} onClick={() => dispatch(projectsNextArr())} /> */}
          <Pagination count={10} pageType={pageType} />

          {/* <Subscription /> */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.8 }}
          >
            <motion.div
              variants={variants}
              transition={{ type: "Tween", delay: 0.3, duration: 0.7 }}
            >
              <Banner color={"green"} btnColor={"white"} />
            </motion.div>
          </motion.div>
          <SeoBlock />
          <div className={"centeredImg"}>
            <a
              href="https://theresanaiforthat.com/ai/paintit/?ref=featured&v=212002"
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="300"
                alt="Banner"
                src="https://media.theresanaiforthat.com/featured3.png"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
